import React from 'react'
// import { createStyles } from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
// import { Theme } from '@material-ui/core/styles/createMuiTheme'
import ProjectCard from './ProjectCard/ProjectCard'
import { Grid, GridProps } from '@mui/material'

// const useStyles = makeStyles((theme: Theme) => createStyles({}))

const GridItem: React.FC<
    Omit<GridProps, 'item' | 'container' | 'xs' | 'md' | 'lg' | 'alignItems'>
> = ({ children, ...others }) => (
    <Grid item xs={12} sm={6} md={4} {...others}>
        {children}
    </Grid>
)

const Projects: React.FC = () => {
    // const styles = useStyles()
    return (
        <Grid container spacing={2} style={{ marginTop: 16 }}>
            <GridItem>
                <ProjectCard
                    title='MakeMyMeal'
                    description='Coming soon...'
                    stacks={[
                        {
                            title: 'CI/CD',
                            list: [
                                'Terraform',
                                'GitHub',
                                'Docker',
                                'AWS CodePipeline',
                                'Serverless Framework',
                                'More TBD',
                            ],
                        },
                        {
                            title: 'Backend stack',
                            list: [
                                'Node.js',
                                'Typescript',
                                'GraphQL',
                                'Lambda',
                                'Elasticsearch',
                                'DynamoDB',
                                'API Gateway',
                                'Cognito',
                            ],
                        },
                        {
                            title: 'Frontend stack',
                            list: ['TBD'],
                        },
                    ]}
                />
            </GridItem>
            <GridItem>
                <ProjectCard
                    title='Streetspares.com'
                    description='A search engine for car parts.'
                    stacks={[
                        {
                            title: 'CI/CD',
                            list: [
                                'Terraform',
                                'GitHub',
                                'Docker',
                                'AWS CodeBuild',
                                'AWS ECR',
                            ],
                        },
                        {
                            title: 'Backend stack',
                            list: [
                                'Node.js',
                                'Typescript',
                                'Puppeteer',
                                'Cheerio',
                                'Elasticsearch',
                            ],
                        },
                        {
                            title: 'Frontend stack',
                            list: [
                                'Next.js',
                                'Typescript',
                                'React',
                                'React-query',
                                'Redux',
                                'Material UI',
                                'React-spring',
                            ],
                        },
                    ]}
                    to='https://streetspares.com'
                />
            </GridItem>
            <GridItem>
                <ProjectCard
                    title='Scrapers'
                    description='A system of standardized scrapers to quickly and reliably crawl data.'
                    stacks={[
                        {
                            title: 'CI/CD',
                            list: [
                                'Terraform',
                                'GitHub',
                                'Docker',
                                'AWS CodeBuild',
                                'AWS ECR',
                            ],
                        },
                        {
                            title: 'Backend stack',
                            list: [
                                'Node.js',
                                'Typescript',
                                'Docker',
                                'Puppeteer',
                                'Cheerio',
                            ],
                        },
                    ]}
                />
            </GridItem>
            <GridItem>
                <ProjectCard
                    title='Custom PC Configurator'
                    description='Proof of concept for a PC configurator.'
                    stacks={[
                        {
                            title: 'Research stack',
                            list: ['Hotjar screen recording', 'Adobe InDesign'],
                        },
                        {
                            title: 'Design stack',
                            list: ['Adobe XD'],
                        },
                        {
                            title: 'Frontend stack',
                            list: [
                                'Typescript',
                                'React',
                                'React Router',
                                'Redux-like state management',
                                'React-spring',
                            ],
                        },
                    ]}
                    to='/projects/custom-pc-configurator'
                />
            </GridItem>
            <GridItem>
                <ProjectCard
                    title='Mwave NUCs'
                    description='Micro site promoting Intel NUC computers.'
                    stacks={[
                        {
                            title: 'Design stack',
                            list: ['Adobe XD'],
                        },
                        {
                            title: 'Frontend stack',
                            list: ['Javascript', 'Pug', 'SCSS', 'PHP'],
                        },
                    ]}
                    to='https://nuc.mwave.com.au'
                />
            </GridItem>
        </Grid>
    )
}

export default Projects
