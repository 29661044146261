import React from 'react'
import { Chip, ChipProps, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
// @ts-ignore
import TransitionLink from 'gatsby-plugin-transition-link'
import ContentSection, {
    ContentSectionProps,
} from '../../ContentSection/ContentSection'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            display: 'flex',
            flexDirection: 'column',

            '&:hover': {
                boxShadow: theme.shadows[8],
            },
        },
        section: {
            '&:not(:first-child)': {
                marginTop: theme.spacing(1.5),
            },
        },
        description: {
            marginBottom: theme.spacing(1.5),
        },
        list: {
            '&&&': {
                display: 'flex',
                flexWrap: 'wrap',
                listStyle: 'none',
                margin: 0,

                '& > li': {
                    margin: 0,
                },
            },
        },
        cardFooter: {
            backgroundColor: '#f4f7fb',
            margin: theme.spacing(0, -2, -2, -2),
            padding: 16,
        },
        chip: {
            margin: `${theme.spacing(1)} ${theme.spacing(0.5)}`,
            cursor: 'inherit',
            backgroundColor: '#e2ecf8',
        },
    })
)

export type ProjectCardProps = ContentSectionProps & {
    title: string
    description?: string
    stacks?: { title: string; list: string[] }[]
    to?: string
}

const ProjectCardWrapper: React.FC<Pick<ProjectCardProps, 'to'>> = (props) => {
    const { to, children } = props
    if (!to) return <>{children}</>
    const isExternal = /http/.test(to)
    return (
        <>
            {isExternal ? (
                <a href={to} target={'_blank'} rel={'noreferrer noopener'}>
                    {children}
                </a>
            ) : (
                <TransitionLink to={to} exit={{ length: 0.35 }}>
                    {children}
                </TransitionLink>
            )}
        </>
    )
}

const CustomChip = (props: ChipProps) => {
    const styles = useStyles()
    return <Chip className={styles.chip} size='small' {...props} />
}

const ProjectCard: React.FC<ProjectCardProps> = (props: ProjectCardProps) => {
    const { title, description, stacks, to, ...others } = props

    const styles = useStyles()

    return (
        <ProjectCardWrapper to={to}>
            <ContentSection
                container
                title={title}
                className={styles.card}
                {...others}
            >
                {description && (
                    <Typography className={styles.description}>
                        {description}
                    </Typography>
                )}

                <section className={styles.cardFooter}>
                    {stacks &&
                        stacks.map((s) => (
                            <div key={s.title} className={styles.section}>
                                <Typography variant='subtitle2' component='p'>
                                    {s.title}
                                </Typography>
                                <ul className={styles.list}>
                                    {s.list.map((d, i) => (
                                        <li key={i}>
                                            <CustomChip label={d} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                </section>
            </ContentSection>
        </ProjectCardWrapper>
    )
}

export default ProjectCard
