import React from 'react'

import SEO from '../components/SEO/SEO'
import Projects from '../components/Projects/Projects'
import Transition from '../components/Transition/Transition'
import PageTitle from '../components/PageTitle/PageTitle'

const ProjectsPage: React.FC = () => (
    <Transition>
        <SEO title='Projects' />
        <PageTitle title={'Projects'} />
        <Projects />
    </Transition>
)

export default ProjectsPage
